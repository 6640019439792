import "../styles/index.scss";
import type { AppProps } from "next/app";
import Footer from "../components/Footer";
import MyNavbar from "../components/navbar";
import Head from "next/head";
import img1 from '../images/server-error/1.png'
import img2 from '../images/server-error/2.png'
import img3 from '../images/server-error/3.png'
import Image from "next/image";
function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Gurucool - Coolfluencing the world</title>
      </Head>
      <MyNavbar />
      <main className="pt-[62px] lg:pt-0 main-container">
        <Component {...pageProps} />
      </main>
      <Footer />
      			{/* <div className="p-6 flex justify-center items-center h-screen flex-col">
					<div className="mb-4"><Image src={img1} width={180} height={180} alt=""/></div>
					<div className="mb-4"><Image src={img2} width={288} height={22} alt=""/></div>
					<div><Image src={img3} width={409} height={42} alt=""/></div>
				</div> */}
    </>
  );
}

export default MyApp;
