import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import Link from "next/link";
import pLogo from "../images/g-logo.png";

const MyNavbar = () => {
  const router = useRouter();
  const [navOpen, setNavOpen] = useState(false);
  const handleToggle = () => {
    setNavOpen((prev) => !prev);
  };

  return (
    <header className="bg-white text-black lg:border-b lg:border-[#e8eaed] fixed top-0 w-full lg:static z-[1000]">
      <div className="flex items-center justify-between px-4 lg:px-5 max-w-6xl lg:mx-auto">
        <Link href="/" passHref>
          <a className="w-32 lg:w-56 -mb-1 cursor-pointer p-0 py-2 lg:py-0 ">
            <Image
              alt="logo"
              src="/logo.png"
              width="150px"
              height="50px"
              loading="eager"
              objectFit="contain"
            ></Image>
          </a>
        </Link>
        <div className="relative z-[1003] p-1 rounded-[3px] sm:ml-5 lg:hidden ">
          {navOpen ? (
            <button onClick={handleToggle}>
              <i className="fa-light fa-xmark fa-lg" />
            </button>
          ) : (
            <button onClick={handleToggle}>
              <i className="fa-light fa-bars fa-lg" />
            </button>
          )}
        </div>
        <nav
          className={
            "flex flex-col items-start pl-7 sm:items-center sm:pl-0 bg-white absolute top-0 right-0 left-0 z-[1002] min-h-screen lg:min-h-0 pt-32 lg:p-0 lg:static lg:translate-x-0 transform transition-transform duration-500 ease-in-out " +
            (navOpen ? "translate-x-0" : "translate-x-full")
          }
        >
          <ul className="flex flex-col lg:flex-row lg:items-center gap-7 lg:gap-0 text-sm">
            <li
              className={
                "w-fit " +
                (router.pathname == "/"
                  ? "border-b-2 border-primary"
                  : "nonactive")
              }
            >
              <Link href="/" passHref>
                <a
                  className="text-xl font-medium hover:text-primary xl:px-10 lg:px-9 py-3 lg:py-5 inline-block"
                  onClick={handleToggle}
                >
                  {" "}
                  Our Story{" "}
                </a>
              </Link>
            </li>
            <li
              className={
                "w-fit " +
                (router.pathname == "/people"
                  ? "border-b-2 border-primary"
                  : "nonactive")
              }
            >
              <Link href="/people" passHref>
                <a
                  className="text-xl font-medium hover:text-primary xl:px-10 lg:px-9 py-3 lg:py-5 inline-block"
                  onClick={handleToggle}
                >
                  People
                </a>
              </Link>
            </li>
            <li
              className={
                "w-fit " +
                (router.pathname == "/tools"
                  ? "border-b-2 border-primary"
                  : "nonactive")
              }
            >
              <Link href="/tools" passHref>
                <a
                  className="text-xl font-medium hover:text-primary xl:px-10 lg:px-9 py-3 lg:py-5 inline-block"
                  onClick={handleToggle}
                >
                  Tools
                </a>
              </Link>
            </li>
            <li
              className={
                "w-fit " +
                (router.pathname == "/content"
                  ? "border-b-2 border-primary"
                  : "nonactive")
              }
            >
              <Link href="/content" passHref>
                <a
                  className="text-xl font-medium hover:text-primary xl:px-10 lg:px-9 py-3 lg:py-5 inline-block"
                  onClick={handleToggle}
                >
                  Content
                </a>
              </Link>
            </li>
          </ul>
          <Link href="https://play.google.com/store/apps/details?id=com.gcpadhaai.padhaai">
            <a
              className="bg-black px-10 mt-14 py-3 sm:ml-24 text-white text-lg font-medium flex gap-2 items-center lg:hidden rounded-full transform hover:-translate-y-[2px] transition duration-200 cubic ease-[cubic-bezier(.3, .7, .4, 1.5)] hover:shadow-2xl hover:shadow-primary"
            >
              <span className="h-[25px] block w-[25px]">
              <Image
                src={pLogo}
                width={25}
                height={25}
                alt=""
                objectFit="contain"
                  className=""
                />
              </span>
              <span>Download</span>
            </a>
          </Link>
        </nav>
        <Link href="https://play.google.com/store/apps/details?id=com.gcpadhaai.padhaai">
          <a
            target="_blank"
            rel="noreferrer"
            className="bg-black px-6 py-3 text-white text-lg font-medium hidden lg:flex gap-2 items-center rounded-full transform hover:-translate-y-[2px] transition duration-200 cubic ease-[cubic-bezier(.3, .7, .4, 1.5)] hover:shadow-2xl"
          >
            <span className="h-[25px] block w-[25px]">
              <Image
                src={pLogo}
                width={25}
                height={25}
                alt=""
                objectFit="contain"
                className=""
              />
            </span>
            <span>Download</span>
          </a>
        </Link>
      </div>
    </header>
  );
};
export default MyNavbar;
